import React , {useEffect,useState , useRef}from "react";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { RWebShare } from "react-web-share";
import { QRCode } from 'react-qrcode-logo';
import { pdfjs } from "react-pdf";
import tinycolor from "tinycolor2";
import { FaPhone } from "react-icons/fa6";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { RiWhatsappLine } from "react-icons/ri";
import { MdOutlineAddLocation } from "react-icons/md";

import { FaBookmark } from "react-icons/fa";
import { MdEmail} from "react-icons/md";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { TbCalendarPlus } from "react-icons/tb";

import { FaPlus } from "react-icons/fa";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { IoShareSocial } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"; 
import axios from "axios";
import { ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import { IoMdImages, IoMdVideocam } from 'react-icons/io';
import { MdOutlineShare, MdOutlineArticle } from 'react-icons/md'; // New modern icons
import { FaShareFromSquare } from "react-icons/fa6";

import { color, motion } from "framer-motion"; // Ensure this is installed
import TebLogo from '../image/Logo.png'
import PostGallery from '../Components/PostsPage'
const TemplateThree = () => { 
  const [isExpanded, setIsExpanded] = useState(false);
  const handleAddToContactsClick = () => {
    setIsExpanded(true);

    // Auto-collapse after 2 seconds
    setTimeout(() => {
      setIsExpanded(false);
    }, 2000);
  };

  // Animation variants
 

  // Spark animation for visual effects
  const sparkVariants = {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      opacity: [0, 1, 0],
      scale: [0.5, 1.5, 0.5],
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      },
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(true);

      // Auto-collapse after a delay
      setTimeout(() => {
        setIsExpanded(false);
      }, 2000);
    }, 500); // Add slight delay before expanding on page load
  }, []);


 
 const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [animate, setAnimate] = useState(false);
  const lang = userData?.SelectedLanguage || 'en'; // Default to English if undefined
  const [value, setValue] = useState("socials");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [position, setPosition] = useState({
    left: 0,
    width: 0,
    opacity: 1,
  });

  // Refs for individual buttons
  const socialsRef = useRef(null);
  const postsRef = useRef(null);
  const [socialsElement, setSocialsElement] = useState(null);
  const [postsElement, setPostsElement] = useState(null);
  const isValidValue = (value) => {
    return (
      value !== null &&
      value !== "null" &&
      value !== undefined &&
      value !== "undefined" &&
      value !== ""
    );
  };
  
  const bgColor = userData?.bgColor || "#f0f0f0"; // Dynamic background color
  const buttonColor = userData?.buttonColor || "#ff5722"; // Dynamic cursor color

  const handleChange = (newValue, element) => {
    setValue(newValue);

    const { width, left } = element.getBoundingClientRect();
    const navLeft = element.parentElement.getBoundingClientRect().left;

    setPosition({
      left: left - navLeft,
      width,
      opacity: 1,
    });
  };
  useEffect(() => {
    // Set initial position on "Socials" using callback ref value
    if (socialsElement) {
      const { width, left } = socialsElement.getBoundingClientRect();
      const navLeft = socialsElement.parentElement.getBoundingClientRect().left;

      setPosition({
        left: left - navLeft,
        width,
        opacity: 1,
      });
    }
  }, [socialsElement]); // Run this when the socialsElement is set


  console.log(userData?.second_links,"asdaa")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const translations = {
    en: {
      phone: "Phone",
      email: "Email",
      whatsapp: "WhatsApp",
      bookNow: "Book Now",
      addToContacts: "Add To Contacts",
      copyLink: "Copy Link",
      shareLink: "Share Link",
      myQrCode: "My QR Code",
      socials: "Socials",
      posts: "Posts",
      contactUs: "Contact Us Via:",
      emailContact: "EMAIL"
    },
    ar: {
      phone: "هاتف",
      email: "البريد الإلكتروني",
      whatsapp: "واتساب",
      bookNow: "احجز الآن",
      addToContacts: "جهات الاتصال",
      copyLink: "نسخ الرابط",
      shareLink: "مشاركة الرابط",
      myQrCode: "رمز الاستجابة السريعة الخاص بي",
      socials: "التواصل الاجتماعي",
      posts: "المنشورات",
      contactUs: "اتصل بنا عبر:",
      emailContact: "البريد الإلكتروني"
    }
  };
 
  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; 
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const [oppen, setOppen] = React.useState(false);
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");

  console.log("userParam", userParam);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try { 
        const response = await axios.get(
          `https://tebsocial.api.cubex-dev.com/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log(response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://tebsocial.api.cubex-dev.com/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);

    useEffect(() => {
    const handleDocumentClick = (e) => {
      if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
        setOppen(false); // Close SpeedDial
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  let apicounter = 0;
// const isPersonal= userData?.isPersonal =="true" ? true : false
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
const [isPersonal,setisPersonal]=useState(false)
  const [inactiveColor, setinactiveColor] = useState("#ffffff");

    useEffect(() => {

     if (userData?.isPersonal =='true'){
    setisPersonal(true)
    console.log(userData?.isPersonal,'userData?.isPersonal')
console.log(isPersonal,'boolian isPersonal')

  }
    
    else {
        setisPersonal(false)
console.log(userData,'userData?.isPersonal')
console.log(isPersonal,'boolian isPersonal')

    }

  }, [userData?.isPersonal,isPersonal]);

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
      setinactiveColor(tinycolor(textcolor).lighten(30).toString())
    }
  }, [userData?.buttonColor]);


  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("user");

  console.log("user:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

   const handleCopyLink = () => {
    navigator.clipboard.writeText(urlToShare);
    setIsVisible(true); // Show "link copied" message
    setTimeout(() => {
      setIsVisible(false);
    }, 1000);
  };

  const handleOpenQRModal = () => {
    setOpen(true);
  };
  const handleCloseQRModal = () => setOpen(false);

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
 const toDataURL = (url) =>
  axios.get(url, {
    responseType: "blob",
    headers: {
      'Accept': '*/*' // Assuming the server responds with any type of content
    }
  })
  .then((response) => {
    console.log(`Blob Size: ${response.data.size} bytes`);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Data URL:', reader.result); // Log the data URL
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.error("FileReader Error:", error);
        reject(error);
      };
      reader.readAsDataURL(response.data);
    });
  })
  .catch((error) => {
    console.error("Error fetching and converting data:", error);
    return null; // Return null on error to handle gracefully
  });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      TebSocialWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (userData?.photo !== "") {
  imageDataUrl = await toDataURL(userData?.photo);
        console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.TebSocialWebsite}\n` +
      `item1.X-ABLabel:Teb Social Profile\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

// Handling primary_links
contact?.primary_links?.forEach((link, index) => {
  const itemNumber = index + 3; // Starting from 3 as 1 is used for TebSocialWebsite
  vcard +=
    `item${itemNumber}.URL:${link.value}\n` +
    `item${itemNumber}.X-ABLabel:${link.name}\n`;
});

  const itemNumber = (contact?.primary_links?.length || 0) + 3;
  vcard += 
    `item${itemNumber}.URL:${userData.second_links[0]?.link}\n` +
    `item${itemNumber}.X-ABLabel:Medical specialty\n`;




    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);


  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://tebsocial.api.cubex-dev.com/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };


const [openPost, setOpenPost] = useState(false);
const [selectedPost, setSelectedPost] = useState(null);
const handleOpenPost = (post) => {
  setSelectedPost(post);
  setOpenPost(true);
};

  const handleSpeedDialClick = () => {
    setOppen(!oppen);
    setTooltipOpen(!tooltipOpen); // Toggle tooltip visibility based on SpeedDial state
  };
  const speedDialAnimation = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.5 } },
  };

  const buttonAnimation = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.7, delay: 0.3 } },
  };

 const buttonVariants = {
    initial: { 
      width: "56px",
      height: "56px", // Ensure it's always a circle
      borderRadius: "50%", // Make it fully circular
      padding: "10px",
      borderColor:textcolor

    },
    expanded: {
      width: "200px",
      height: "56px",
      borderRadius: "56px", // Keep circular ends
      padding: "10px 20px",
      transition: {
        type: "spring",
        stiffness: 180,
        damping: 18,
        duration: 0.6,
      },
      borderColor:textcolor

    },
    collapsed: {
      width: "56px",
      height: "56px",
      borderRadius: "50%", // Fully circular
      padding: "10px",
      transition: {
        type: "spring",
        stiffness: 180,
        damping: 18,
        duration: 0.6,
      },
      borderColor:textcolor

    },
  };
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> 
      </div>
    );
  }
  return (
    <div className={`w-screen h-screen ${lang == 'ar' ? 'text-right' : 'text-left'}`} style={{ backgroundColor: userData.bgColor }}>
    <div className="w-screen h-screen" style={{ backgroundColor: userData?.bgColor }}>
        <div style={{ backgroundColor: userData?.bgColor , marginLeft:"10px",
                    marginRight:"10px"}}>
            <div  
                className="fixed w-screen h-screen -z-10 "
                style={{ backgroundColor: userData?.bgColor}}
            ></div>
            <div className="relative flex justify-center">
<div
  className="relative w-[94%] h-full z-10 mt-3 transition-all duration-500 ease-linear mb-2 rounded-[30px] overflow-hidden"
>
  <img
    src={userData?.cover}
    alt="Cover Photo"
    className="object-cover w-full h-full"
    style={{
      width: "100%", // Full width of the container
      height: "auto", // Maintains aspect ratio by adjusting the height automatically
      objectFit: "contain", // Ensures the image fits within the container while maintaining aspect ratio
      maxHeight: "100%", // Optional: Limits the maximum height to 50% of the container height
      maxWidth: "100%", // Optional: Limits the maximum width to 80% of the container
       borderRadius: "30px",
    transition: "0.5s filter linear",
    border: `4px ${userData?.buttonColor} solid`,
    margin: "0 auto", // Centers the image horizontally
    }}
  />
</div>          
            <img
              className="w-32 h-auto z-20 absolute -bottom-16 md:-bottom-20 rounded-full border-4"
              style={{
                border: `4px ${userData?.buttonColor} solid`,
              }}
              src={userData?.photo}
              alt="User Photo"
            />
          </div>
          
        <div
      className="relative text-center border-2 mt-4"
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "auto",
        
        transition: "0.5s filter linear",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "30px",
        border: `4px ${userData?.buttonColor} solid`,
        paddingBottom:"10px"
      }}
    >
      <motion.div
        className="pt-16 "
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        {isPersonal === true ? (
          <motion.h2
            className="pb-3 text-xl font-bold"
            style={{ color: textcolor, fontWeight: "bold", fontSize: "1.8rem" }}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1, duration: 0.7 }}
          >
          {isValidValue(userData?.firstName) && isValidValue(userData?.lastName)
              ? `${userData.firstName} ${userData.lastName}`
              : ""}
          </motion.h2>
        ) : (
          <motion.h2
            className="pb-2 md:pb-3 text-xl font-bold"
            style={{ color: textcolor, fontWeight: "bold", fontSize: "1.8rem" }}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1, duration: 0.7 }}
          >
            {isValidValue(userData?.businessName) ? userData.businessName : ""}
          </motion.h2>
        )}
      </motion.div>

      
<div classname=' mt-6'>
      {/* Second Links */}
      {userData?.second_links?.length == 1 || isPersonal == true && userData?.second_links?.length >= 1  ? (
        <motion.h2
          className="text-xs font-semibold "
          style={{ color: textcolor }}
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          {userData.second_links[0]?.link}
        </motion.h2>
      ) : (
         isPersonal == false &&  userData?.second_links?.map((primaryItem, primaryIndex) => (
          <motion.h2
            key={primaryIndex}
            className="text-xs font-semibold"
            style={{ color: textcolor }}
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 + primaryIndex * 0.2, duration: 0.8 }}
          >
              {isValidValue(primaryItem?.link) ? primaryItem?.link : ""}
          </motion.h2>
        ))
      )}
</div>
      {/* Conditional Text for Business Name */}
      {isPersonal == true && isValidValue(userData?.businessName) && (
        <motion.h2
          className="pt-2 md:pt-2 text-2xl font-bold"
          style={{ color: textcolor, fontWeight: "bold", fontSize: "1.5rem" }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          {userData.businessName}
        </motion.h2>
      )}

      {/* Location */}
      {isValidValue(userData?.location) && (
<motion.h2
  className="text-sm Robote pb-1  flex justify-center"
  style={{
    color: textcolor,
    textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
    padding: '5px', // Add padding
  }}
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4, duration: 0.8 }}
>
  <MdOutlineAddLocation  color={textcolor} size={20}/>

<span className='px-2'>  {userData?.location} </span>
</motion.h2>
      )}

      {/* Bio */}
      {isValidValue(userData?.bio) && (
        <motion.h2
          className="text-md font-semibold "
          style={{ color: textcolor }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          {userData?.bio}
        </motion.h2>
      )}
    </div>
        </div>
        <div className="rounded-lg flex flex-row justify-between items-center gap-2 px-[6%] pb-5 pt-10">
  <button className="w-14 h-14 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
      window.open(`tel:${cleanedPhoneNum}`);
    }}>
    <LiaPhoneVolumeSolid size={36} />
    <p className='text-sm whitespace-nowrap'>{translations[lang].phone}</p>
  </button>

  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    
    }}
    onClick={() => {
      window.open(`mailto:${userData.email}`);
    }}>
    <MdOutlineMarkEmailUnread size={36} />
    <p className='text-sm whitespace-nowrap'>{translations[lang].email}</p>
  </button>
{
userData?.phoneNumberWA  ? 
  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      const cleanedPhoneNum = userData.phoneNumberWA.replace(/\s+/g, ""); // Remove all spaces
      window.open(`https://wa.me/${cleanedPhoneNum}`);
    }}>
    <RiWhatsappLine size={36}/>
    <p className='text-sm whitespace-nowrap'>{translations[lang].whatsapp}</p>
  </button>
  :""
}
{userData?.jobTitle ?
  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      window.open(`${userData.jobTitle}`);
    }}>
    <TbCalendarPlus size={36}/>
    <p className='text-sm whitespace-nowrap'>{translations[lang].bookNow}</p>
  </button>
  :''}
</div>
<div className=' flex justify-center'>
     <div
      className="relative flex w-[100vw]  p-1 mb-10"
      style={{
  backgroundColor: bgColor, // Use userData background color
  borderTop: `2px solid ${buttonColor}`, // Border for the top
  borderBottom: `2px solid ${buttonColor}`, // Border for the bottom

      }}
    >
      <ul className="relative flex justify-around w-full z-10">
        {/* Socials tab */}
        <li
          ref={setSocialsElement} // Set ref using callback ref pattern
          className="relative flex items-center cursor-pointer px-4 py-2 text-center transition-all duration-300"
          onClick={() => handleChange("socials", socialsElement)}
          style={{
            color: value === "socials" ? textbottoncolor : textcolor,
            fontWeight: value === "socials" ? "bold" : "normal",
          }}
        >
          <MdOutlineShare size="24px" />
          <span className="ml-2">
          {lang === 'ar' ? translations.ar.socials : translations.en.socials}
        </span>
        </li>

        {/* Posts tab */}
        <li
          ref={setPostsElement} // Set ref using callback ref pattern
          className="relative flex items-center cursor-pointer px-4 py-2 text-center transition-all duration-300"
          onClick={() => handleChange("posts", postsElement)}
          style={{
            color: value === "posts" ? textbottoncolor : textcolor,
            fontWeight: value === "posts" ? "bold" : "normal",
          }}
        >
          <MdOutlineArticle size="24px" />
        <span className="ml-2">
          {lang === 'ar' ? translations.ar.posts : translations.en.posts}
        </span>
                </li>
      </ul>

      {/* Animated cursor with background effect */}
      <motion.div
        animate={position}
        className="absolute top-0 bottom-0 z-0 rounded-[20px]"
        style={{
          height: "77%", // Full height of the bar
          marginTop:"5px",
          
          backgroundColor: buttonColor, // Use userData button color for the cursor background
  //             borderTopLeftRadius: "10px", // Radius for the bottom-left corner
  // borderTopRightRadius: "10px", // Radius for the bottom-right corner

          }}
     
      />
    </div>
</div>
{
<div
  className="w-screen flex justify-center"
  style={{
    backgroundColor: value === 'socials' ? userData?.bgColor : buttonColor
  }}
>
  <div className={`w-[100%] ${value === 'socials' ? 'mb-[200px]' : ''}`}>
    {value == 'socials' ? (
     <div className="grid grid-cols-3 justify-center items-center  mt-5 gap-y-12  px-[10px] place-items-center">
          {userData?.primary_links?.map((primaryItem, primaryIndex) => (
            <div
              className="animate-shakeX"
              key={primaryIndex}
              style={{
                width: "50px", // Fixed width for the container
                height: "50px", // Fixed height for the container
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                display:"relative"
              }}
            >
              {userData?.primary_links?.[primaryIndex]?.name !== "Telegram" ? (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    src={primaryItem?.logo}
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              ) : (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    onClick={() => {
                      const cleanedPhoneNum = userData.phoneNum.replace(
                        /\s+/g,
                        ""
                      ); 
                      const telegramUsername =
                        userData?.primary_links?.[primaryIndex]?.value; // Replace with the actual username or ID
                      window.open(`https://t.me/${telegramUsername}`);
                    }}
                    src={primaryItem?.logo}
                    on
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              )}
            </div>
          ))}

        
        </div>
    ) :
     
    (

 <div className=' pb-32 '  style={{ backgroundColor: userData?.bgColor }}>
      <div classname='w-full h-full  ' style={{ backgroundColor: userData?.bgColor }}
      >
           <PostGallery userData={userData}/>
           </div>  </div>
    )}
  </div>
</div>
}



 


   
      <div onClick={()=>{saveToContacts()}}>
      <motion.div
        onClick={handleAddToContactsClick}
        initial="initial"
        animate={isExpanded ? "expanded" : "collapsed"}
        variants={buttonVariants}
        className="fixed right-4 bottom-10 z-50 flex items-center justify-center rounded-full "
        style={{
          backgroundColor: buttonColor,
          borderColor: textbottoncolor,
          border: "2px solid",
        }}
      >
        {/* Icon */}
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: isExpanded ? 45 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <FaPlus size={20} color={textbottoncolor} />
        </motion.div>

        {/* Text (visible only when expanded) */}
        {isExpanded && (
          <motion.p
            className="ml-2 font-bold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ color: textbottoncolor }}
          >
            {translations[lang].addToContacts}
          </motion.p>
        )}

        {/* Spark Effect */}
        {isExpanded && (
          <motion.div
            className="absolute"
            variants={sparkVariants}
            initial="initial"
            animate="animate"
            style={{
              top: "-20px",
              right: "-20px",
              width: "10px",
              height: "10px",
              backgroundColor: buttonColor,
              borderRadius: "50%",
              boxShadow: `0 0 8px ${buttonColor}`,
            }}
          />
        )}

        {/* Add more sparks for effect */}
        {isExpanded && (
          <>
            <motion.div
              className="absolute"
              variants={sparkVariants}
              initial="initial"
              animate="animate"
              style={{
                bottom: "-20px",
                left: "-20px",
                width: "10px",
                height: "10px",
                backgroundColor: buttonColor,
                borderRadius: "50%",
                boxShadow: `0 0 8px ${buttonColor}`,
              }}
            />
            <motion.div
              className="absolute"
              variants={sparkVariants}
              initial="initial"
              animate="animate"
              style={{
                top: "-20px",
                left: "-20px",
                width: "10px",
                height: "10px",
                backgroundColor: buttonColor,
                borderRadius: "50%",
                boxShadow: `0 0 8px ${buttonColor}`,
              }}
            />
          </>
        )}
      </motion.div>
</div>


       <Box className="fixed z-50 bottom-4 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
           <Modal
               open={open}
               onClose={handleClose}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
           >
               <Box
                   sx={{
                       position: "absolute",
                       top: "50%",
                       left: "50%",
                       transform: "translate(-50%, -50%)",
                       width: '270px',
                       height: '270px',
                      
                   }}
               >
                   <div
                       style={{
                           borderRadius: "20px", // Adjust as needed for roundness
                           overflow: "hidden",
                       }}
                   >
                       <QRCode
     value={urlToShare}            // here you should keep the link/value(string) for which you are generation promocode
      logoImage={`${userData?.photo}`}  // URL of the logo you want to use, make sure it is a dynamic url
      logoHeight={40}
      logoWidth={40}
      logoOpacity={1}  
      bgColor={userData?.buttonColor}        
      fgColor={textbottoncolor}
      size={250}
                            qrStyle="dots" 
                     eyeRadius={10} 
                   
                       />
      
                   </div>
               </Box>
           </Modal>
           <Box ref={speedDialRef} className='fixed bottom-0  left-4 '   style={{
             whiteSpace: "nowrap",
             zIndex: "0",
             color: `black`,
             position:"fixed",
             zIndex:100,
           }}>


<motion.div
            variants={speedDialAnimation}
            initial="initial"
            animate="animate"
          >

       <SpeedDial
        FabProps={{ size: "medium", style: { backgroundColor: "#000" } }}
        icon={<FaShareFromSquare size={'28'} color={textbottoncolor} />}
      ariaLabel="Teb social Shares"
      onClick={handleSpeedDialClick} // Toggle tooltips and SpeedDial state
      sx={{
        position: 'absolute', 

        bottom: 35,
        color: `${textbottoncolor}`,
        left: 16,
        zIndex: 40,
        '& .MuiSpeedDial-fab': {
          backgroundColor: `${userData?.buttonColor} !important`,
          '&:hover': {
            backgroundColor: `${userData?.buttonColor} !important`,
          },
                border: `2px solid ${textcolor}`, // Add 2px border to the main SpeedDial button

        },
      }}
     
      open={oppen}
    >
    <SpeedDialAction
    sx={{
          '&:hover': {
            transform: 'scale(1.05)',
          },
          

        }}
    FabProps={{ size: "medium", style: { backgroundColor: userData?.buttonColor } }}

  icon={<IoQrCodeOutline style={{ color: textbottoncolor }} size={25} />}
  tooltipTitle={translations[lang].myQrCode}
  tooltipPlacement="right"
  tooltipOpen
  onClick={handleOpen}
/>

      {/* Copy Link Action */}
      <SpeedDialAction
       sx={{
          '&:hover': {
            transform: 'scale(1.05)',
          },
          

        }}
    FabProps={{ size: "medium", style: { backgroundColor: userData?.buttonColor } }}

        icon={<FaRegCopy size={25} style={{ color: textbottoncolor }} />}
        tooltipTitle={translations[lang].copyLink}
        tooltipPlacement="right"
tooltipOpen       
 onClick={handleCopyLink}
      />

      {/* Share Action */}
      <SpeedDialAction
       sx={{
          '&:hover': {
            transform: 'scale(1.05)',
          },
          

        }}
    FabProps={{ size: "medium", style: { backgroundColor: userData?.buttonColor } }}

        icon={
          <RWebShare
            data={{
              url: urlToShare,
              title: " Teb Social Profile Link",
            }}
            onClick={() => setOppen(!oppen)}
          >
            <IoShareSocial  data={{
              url: urlToShare,
              title: "Share your Teb Social Profile Link",
            }}             onClick={() => setOppen(!oppen)}
 style={{ color: textbottoncolor }} size={25} />
          </RWebShare>
        }
        tooltipTitle={translations[lang].shareLink}
        tooltipPlacement="right"
        tooltipOpen 
     />
    </SpeedDial>
</motion.div>
 </Box>


       </Box>
       {isVisible && (
         <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
             link copied
         </div>
     )}

    </div>
    
  
  
    </div>
);

};

export default TemplateThree;
