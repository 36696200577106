import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Templateone from "./pages/Templateone";
import Templatetwo from "./pages/Templatetwo";
import TemplateThree from "./pages/TemplateThree";
import TemplateFour from "./pages/TemplateFour";
import Template8 from "./pages/Template8";
import Template9 from "./pages/Template9";
import Template10 from "./pages/Template10";
import Template11 from "./pages/Template11";
import Template12 from "./pages/Template12";
import Template13 from "./pages/Template13";
import Template15 from "./pages/Template15";
import Template16 from "./pages/Template16";
import Template17 from "./pages/Template17";
import Navigator from "./pages/Navigator";
import Temp18 from "./pages/Temp18";
function App() {
  return (
   <div className="App">
    <Router>
    <Routes> 
      <Route path="/" element={<Navigator />} />
      <Route path="/TemplateOne" element={<Templateone />} /> {/** fix this one  */}
      
      <Route path="/TemplateThree" element={<TemplateThree />} />
      
      <Route path="/Templatetwo" element={<Templatetwo />} />

      <Route path="/Template8" element={<Template8 />} />
      <Route path="/Template9" element={<Template9 />} />
      <Route path="/Template11" element={<Template11 />} />
      <Route path="/Template12" element={<Template12 />} />
      <Route path="/Template17" element={<Template17/>} /> 
      <Route path="/Template15" element={<Template15 />} />
      <Route path="/Template10" element={<Template10 />} />
      <Route path="/Template13" element={<Template13 />} />
      <Route path="/Template16" element={<Template16/>} />
      <Route path="/Temp18" element={<Temp18/>} />
      <Route path="/TemplateFour" element={<TemplateFour />} />
     {/**  <Route path="/Template6" element={<Template6 />} />*/}
    
    
     
    
     {/** <Route path="/Template14" element={<Template14 />} />*/}  {/** fix it later it needs a company nam */}
     
  
     
    </Routes>
  </Router>
  </div> 
  );
}

export default App;
