import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Template13 from './Template13.js';
import TemplateThree from './TemplateThree';

const DynamicTemplateRenderer = () => {
  const [templateComponent, setTemplateComponent] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userParam, setUserParam] = useState(new URLSearchParams(window.location.search).get("user"));

  useEffect(() => {
    if (!userParam) {
      console.error('No user parameter found in the URL search string.');
      return;
    }
 
    const fetchUserTheme = async () => {
      try {
        const response = await axios.get(`https://tebsocial.api.cubex-dev.com/api/user/${userParam}`);
        const userData = response?.data?.data;
        console.log(userData,'userDatauserData')
        setUserData(userData);
// console.log(theme,'themetheme')
        const theme = userData.theme.id;
        switch (theme) {
          case 1:
            setTemplateComponent(<TemplateThree  />);
            break;
          case 2:
            setTemplateComponent(<Template13 userData={userData} />);
            break;
          default:
            setTemplateComponent(<TemplateThree />); // Default to Templatetwo
            break;
        }
      } catch (error) {
        console.error('Error fetching user theme:', error);
        setTemplateComponent(<div>Error loading the template. Please try again later.</div>);
      }
    };

    fetchUserTheme();
  }, [userParam]);

  return <div>{templateComponent || <div>Loading...</div>}</div>;
};

export default DynamicTemplateRenderer;